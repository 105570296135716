import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className="container-thin">
      <h1>Page Not Found</h1>
      <p>We're sorry, we could't find the page you're looking for.</p>
      <p><a href="/" className="button button--primary">Go To Our Homepage ></a></p>
    </div>
  </Layout>
)

export default NotFoundPage
